// Only REACT_APP_ENV is passed in line 14 of Dockerfile
const BASE_ENDPOINT =
  process.env.REACT_APP_ENV === 'efpay-dev'
    ? 'https://m.efisheries.com'
    : 'https://m.efpay.co';
const API_ENDPOINT = `https://rr-api.efisheries.com`;
const API_ADMIN_ENDPOINT = `https://rr-api.efisheries.com`;
const EMARKET_ADMIN_API = `${BASE_ENDPOINT}/emarket/admin/v1`;
const GROWTH_REPORT_URL =
  process.env.REACT_APP_ENV === 'efpay-dev'
    ? ''
    : 'https://datastudio.google.com/reporting/7095ffb5-7d38-4742-9366-d47dd8a4c8f9';

// TODO: fix loading from env vars
const EFMARKET_USER_ID = process.env.REACT_APP_ENV === 'efpay-dev' ? 39 : 155;
const SHIPPING_AGENT_USER_ID =
  process.env.REACT_APP_ENV === 'efpay-dev' ? 56 : 156;
const CREDIT_USER_ID = process.env.REACT_APP_ENV === 'efpay-dev' ? 174 : 187;

export {
  API_ENDPOINT,
  API_ADMIN_ENDPOINT,
  EMARKET_ADMIN_API,
  GROWTH_REPORT_URL,
  EFMARKET_USER_ID,
  CREDIT_USER_ID,
  SHIPPING_AGENT_USER_ID,
};
