import { makeFetchAction } from 'redux-api-call';
import { createSelector } from 'reselect';
import { get } from 'lodash/fp';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';
import { generateEndpoint } from '../../utils/urlHelpers';

const FETCH_STAFFS_API_ID = 'FETCH_STAFFS';

const {
  actionCreator: fetchStaffsAC,
  dataSelector: staffsRawSelector,
  errorSelector: fetchStaffsErrorSelector,
  isFetchingSelector: isFetchingStaffsSelector,
  updater: updateStaffsAC,
} = makeFetchAction(FETCH_STAFFS_API_ID, (params = {}) => {
  const { page = 1, query = '' } = params;
  return {
    endpoint: generateEndpoint({
      host: `${API_ADMIN_ENDPOINT}/admin/v1/users`,
      params: { page, query },
    }),
    method: 'GET',
  };
});

const totalStaffsSelector = createSelector(
  staffsRawSelector,
  get('paging.total_items')
);

const currentPageSelector = createSelector(
  staffsRawSelector,
  get('paging.current_page')
);

const pageSizeSelector = createSelector(
  staffsRawSelector,
  get('paging.per_page')
);

const staffsDataSelector = createSelector(staffsRawSelector, get('results'));

export {
  FETCH_STAFFS_API_ID,
  fetchStaffsAC,
  staffsDataSelector,
  fetchStaffsErrorSelector,
  isFetchingStaffsSelector,
  updateStaffsAC,
  currentPageSelector,
  pageSizeSelector,
  totalStaffsSelector,
};
