import { Link } from 'react-router-dom';
import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import React from 'react';

import {
  isUpdatingStaffSelector,
  updateStaffAC,
  updateStaffErrorSelector,
} from '../../apiCalls/adminStaffs/edit';
import PasswordForm from './PasswordForm';

const AdminEditStaffPassword = ({
  match,
  handleSubmit,
  formError,
  submitting,
}) => (
  <div className="">
    <div className="">
      <h1 className="page-title">Dashboard</h1>
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to="/iam/staffs">Staffs</Link>
          </li>
          <li>
            <Link to={`/iam/staffs/${match.params.id}`}>
              Staff {match.params.id}
            </Link>
          </li>
          <li>Change password</li>
        </ul>
      </div>

      <div className="">
        <div className="">
          <div className="row align-items-center">
            <div className="error">{formError}</div>
          </div>
          <PasswordForm onFinish={handleSubmit} submitting={submitting} />
        </div>
      </div>
    </div>
  </div>
);

const connectWithRedux = connect(
  state => ({
    submitting: isUpdatingStaffSelector(state),
    updatePasswordError: updateStaffErrorSelector(state),
  }),
  {
    updateStaffPassword: updateStaffAC,
  }
);

const prepareProps = mapProps(
  ({ updateStaffPassword, updatePasswordError, ...others }) => ({
    formError: updatePasswordError,
    handleSubmit: data => {
      const staff_id = others.match.params.id;
      return updateStaffPassword(staff_id, data);
    },
    ...others,
  })
);

const enhance = compose(connectWithRedux, prepareProps);

export default enhance(AdminEditStaffPassword);
