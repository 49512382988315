import { Descriptions, Button } from 'antd';
import { Link } from 'react-router-dom';
import { branch, compose, lifecycle, renderComponent } from 'recompose';
import { connect } from 'react-redux';
import { get } from 'lodash/fp';
import React from 'react';

import { activateStaffAC } from '../../apiCalls/adminStaffs/activate';
import { deactivateStaffAC } from '../../apiCalls/adminStaffs/deactivate';
import { deleteStaffAC } from '../../apiCalls/adminStaffs/delete';
import {
  fetchStaffAC,
  staffDataSelector,
  fetchStaffErrorSelector,
  isFetchingStaffSelector,
} from '../../apiCalls/adminStaffs/get';
import ErrorMessage from '../shared/ErrorMessage';
import LoadingSpinner from '../shared/LoadingSpinner';

const AdminStaffDetail = ({
  staff,
  isActivating,
  activateStaff,
  isDeactivating,
  deactivateStaff,
  deleteStaff,
  match,
  ...others
}) => (
  <div className="">
    <div className="">
      <h1 className="page-title">User Management</h1>
      <div className="breadcrumb">
        <ul>
          <li>
            <Link to="/">Dashboard</Link>
          </li>
          <li>
            <Link to="/iam/staffs">Staffs</Link>
          </li>
          <li>#{match.params.id}</li>
        </ul>
      </div>
      <div className="">
        {staff ? (
          <Descriptions
            title="Staff details"
            bordered
            column={1}
            layout="horizontal"
          >
            <Descriptions.Item label="ID">{staff.id}</Descriptions.Item>
            <Descriptions.Item label="Name">{staff.name}</Descriptions.Item>
            <Descriptions.Item label="Email">{staff.email}</Descriptions.Item>
            <Descriptions.Item label="Phone">{staff.phone}</Descriptions.Item>
          </Descriptions>
        ) : null}
      </div>
      <hr />
      <div className="text-right">
        {staff && (
          <Button.Group>
            <Button>
              <Link
                to={`/iam/staffs/${staff.id}/edit`}
                style={{ textTransform: 'uppercase' }}
              >
                Edit
              </Link>
            </Button>
            <Button>
              <Link
                to={`/iam/staffs/${staff.id}/password`}
                style={{ textTransform: 'uppercase' }}
              >
                Change Password
              </Link>
            </Button>
            <Button
              type="danger"
              onClick={() => {
                if (window.confirm('Are you sure?')) {
                  deleteStaff(staff.id);
                }
              }}
            >
              Delete
            </Button>
          </Button.Group>
        )}
      </div>
    </div>
  </div>
);

const enhance = compose(
  connect(
    state => ({
      staff: staffDataSelector(state),
      isFetching: isFetchingStaffSelector(state),
      error: fetchStaffErrorSelector(state),
    }),
    {
      fetchStaff: fetchStaffAC,
      activateStaff: activateStaffAC,
      deactivateStaff: deactivateStaffAC,
      deleteStaff: deleteStaffAC,
    }
  ),
  lifecycle({
    componentDidMount() {
      // eslint-disable-next-line
      const { id } = this.props.match.params;
      this.props.fetchStaff(id);
    },
  }),
  branch(get('isFetching'), renderComponent(LoadingSpinner)),
  branch(get('errors'), renderComponent(ErrorMessage))
);

export default enhance(AdminStaffDetail);
