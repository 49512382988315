import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'antd';
import StaffFormFields from './StaffFormFields';

const StaffForm = ({ onFinish, submitting, initialValues }) => (
  <Form
    layout="horizontal"
    labelCol={{ span: 2 }}
    wrapperCol={{ span: 12 }}
    onFinish={onFinish}
    initialValues={initialValues}
  >
    <StaffFormFields />
    <div className="bill__buttons row align-items-center">
      <div className="col-md-7 order-md-2">
        <div className="_button-group text-right">
          <Link className="button button--secondary" to="/iam/staffs">
            Dismiss
          </Link>
          <button
            type="submit"
            className="button button--primary"
            disabled={submitting}
            style={{ marginLeft: 10 }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </Form>
);

export const buildFormData = values => {
  const { name, email, roles, phone } = values;
  return {
    name,
    email,
    roles,
    phone,
  };
};

export default StaffForm;
