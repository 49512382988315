import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const DELETE_STAFF_API_ID = 'DELETE_STAFF';

const {
  actionCreator: deleteStaffAC,
  dataSelector: deleteStaffDataSelector,
  errorSelector: deleteStaffErrorSelector,
  isFetchingSelector: isDeletingStaffSelector,
} = makeFetchAction(DELETE_STAFF_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/admin/v1/users/${id}`,
  method: 'DELETE',
  notify: true,
}));

export {
  DELETE_STAFF_API_ID,
  deleteStaffAC,
  deleteStaffDataSelector,
  deleteStaffErrorSelector,
  isDeletingStaffSelector,
};
