import React from 'react';
import { Layout } from 'antd';

import Footer from './Footer';
import Header from './Header';
import SideMenu from './SideMenu';

const EFLayout = ({ children }) => (
  <Layout style={{ minHeight: '100vh' }}>
    <SideMenu />
    <Layout className="site-layout" style={{ marginLeft: 300 }}>
      <Header />
      <Layout.Content
        style={{ overflow: 'initial', padding: 50, backgroundColor: '#fff' }}
      >
        {children}
      </Layout.Content>
      <Footer />
    </Layout>
  </Layout>
);

export default EFLayout;
