import { createSelector } from 'reselect';
import { ACTIONS } from 'redux-api-call';

import { adminAuthTokenSelector } from '../adminSession/state';

const headersSelector = createSelector(adminAuthTokenSelector, authToken => ({
  Authorization: `Bearer ${authToken}`,
  Accept: 'application/json',
}));

const addRequestHeadersMiddleware = ({ getState }) => next => action => {
  if (action === undefined) {
    return;
  }

  const { type, payload } = action;
  if (type === ACTIONS.START) {
    let contentType,
      body = payload.body;
    if (!(payload.body instanceof FormData)) {
      contentType = {
        'Content-Type': 'application/json',
      };
      body = JSON.stringify(payload.body);
    }

    const withHeadersAction = {
      type: ACTIONS.START,
      payload: {
        ...payload,
        body,
        headers: {
          ...headersSelector(getState()),
          ...contentType,
          ...payload.headers,
        },
      },
    };
    next(withHeadersAction);
  } else {
    next(action);
  }
};

export default addRequestHeadersMiddleware;
