import React from 'react';
import { Form } from 'antd';

import RoleSelect from '../shared/RoleSelect';
import TextField from '../shared/form/TextField';
import { FORM_ITEM_LAYOUT } from '../shared/form/config';

const options = [{ value: 'admin', label: 'Admin' }];

const StaffFormFields = () => (
  <>
    <Form.Item
      name="name"
      component={TextField}
      type="text"
      label="Name"
      {...FORM_ITEM_LAYOUT}
    >
      <TextField />
    </Form.Item>
    <Form.Item
      name="email"
      component={TextField}
      type="email"
      label="Email"
      required
      {...FORM_ITEM_LAYOUT}
    >
      <TextField />
    </Form.Item>
    <Form.Item
      name="phone"
      component={TextField}
      type="text"
      label="Phone"
      required
      {...FORM_ITEM_LAYOUT}
    >
      <TextField />
    </Form.Item>
    {
      // <Form.Item
      // name="roles"
      // component={RoleSelect}
      // type="text"
      // label="Roles"
      // options={options}
      // {...FORM_ITEM_LAYOUT}
      // >
      // <RoleSelect options={options} />
      // </Form.Item>
    }
  </>
);
export default StaffFormFields;
