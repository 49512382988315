import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const GET_STAFF_API_ID = 'GET_STAFF';

const {
  actionCreator: fetchStaffAC,
  dataSelector: staffDataSelector,
  errorSelector: fetchStaffErrorSelector,
  isFetchingSelector: isFetchingStaffSelector,
  updater: updateStaffDataAC,
} = makeFetchAction(GET_STAFF_API_ID, id => ({
  endpoint: `${API_ADMIN_ENDPOINT}/admin/v1/users/${id}`,
  method: 'GET',
}));

const updateStaffAC = (staff, state) => {
  const adminStaff = staffDataSelector(state);
  const data = {
    ...adminStaff,
    staff,
  };
  return updateStaffDataAC(data);
};

export {
  GET_STAFF_API_ID,
  fetchStaffAC,
  fetchStaffErrorSelector,
  isFetchingStaffSelector,
  staffDataSelector,
  updateStaffAC,
};
