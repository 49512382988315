import { makeFetchAction } from 'redux-api-call';
import { API_ADMIN_ENDPOINT } from '../../utils/envConfig';

const UPDATE_STAFF_API_ID = 'UPDATE_STAFF';

const {
  actionCreator: updateStaffAC,
  dataSelector: updateStaffDataSelector,
  errorSelector: updateStaffErrorSelector,
  isFetchingSelector: isUpdatingStaffSelector,
} = makeFetchAction(UPDATE_STAFF_API_ID, (id, user) => ({
  endpoint: `${API_ADMIN_ENDPOINT}/admin/v1/users/${id}`,
  method: 'PATCH',
  notify: true,
  body: user,
}));

export {
  UPDATE_STAFF_API_ID,
  updateStaffAC,
  updateStaffDataSelector,
  updateStaffErrorSelector,
  isUpdatingStaffSelector,
};
